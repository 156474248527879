<template>
  <!-- 在线客服 -->
  <div>
    <div class="bg_gray"></div>
    <div class="kefulist">
      <headbox title="联系客服" bgColor="#fff" isborder="true" :isbackPath="true" path="person"></headbox>
      <div class="flex kefubox">
        <div class="headbox">
          <img src="@/assets/images/kefu2.png" alt="" />
        </div>
        <div class="flex2">
          <div class="titlebox"> {{processEnv?'400-860-6806':'13025498949'}}</div>
          <div class="detailsbox">人工客服工作时间：9:00-18:00</div>
        </div>
        <div class="phonekefu" v-if="processEnv">
          <a href="tel:4008606806"
            ><img src="@/assets/images/kefuphone.png" alt=""
          /></a>
        </div>
        <div class="phonekefu" v-if="!processEnv">
          <a href="tel:13025498949"
            ><img src="@/assets/images/kefuphone.png" alt=""
          /></a>
        </div>
      </div>

      <div class="flex kefubox">
        <div class="headbox">
          <img :src="admindetails.managerAvatar" alt="" />
          <!-- @/assets/images/kefu2.png -->
        </div>
        <div class="flex2">
          <div class="titlebox">
            {{ admindetails.managerName || "专属客服" }}
          </div>
          <div class="detailsbox">{{ admindetails.managerDes }}</div>
        </div>
        <div class="phonekefu">
          <a :href="'tel:' + admindetails.managerPhone"
            ><img src="@/assets/images/kefuphone.png" alt=""
          /></a>
        </div>
      </div>
      <!-- 二维码 -->
      <center class="orcodebox">
        <div class="kefuwechat">
          <img :src="kefuImg" alt="" />
        </div>
        <div class="h2">{{GLOBAL}}企业客服微信</div>
        <div class="details">
          截图保存在微信中使用扫一扫打开即可享受更多优质服务
        </div>
      </center>
    </div>
  </div>
</template>
<script>
import headbox from "@/components/head.vue";
import * as kefuapi from "@/utils/api";
export default {
  data() {
    return {
      admindetails: "",
      kefuImg:'',
      processEnv:true
    };
  },
  components: {
    headbox
  },
  mounted() {
    kefuapi.queryContactInfo().then(res => {
      if (res.code == 0) {
        this.admindetails = res.data;
      } else {
        console.log(res);
        this.$toast.fail(res.msg || "网络错误,请稍后再试");
      }
    }).catch((err)=>{console.log('err',err);});
    kefuapi.getAboutPhoto().then(res=>{
      res.forEach(item=>{
         if (item.eventType == "KEFU") this.kefuImg = item.img
      })
    })
  },
  created(){
       process.env.NODE_ENV == 'jqt'?this.processEnv = false:this.processEnv = true

  }
  
};
</script>
<style lang="scss" scoped="true">
.h2 {
  margin: 15px 0 30px;
  color: #333333;
  font-size: 36px;
}
.details {
  color: #999999;
  font-size: 28px;
}
.orcodebox {
  margin: 50px auto;
  width: 400px;
  height: 360px;
}
.flex2 {
  flex: 2;
}
.titlebox {
  font-size: 36px;
  margin: 20px 0 10px;
}
.detailsbox {
  font-size: 28px;
  color: #999999;
}
.phonekefu {
  width: 50px;
  height: 50px;
}
.bg_gray {
  background: #f5f5f5;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}
.kefulist {
  position: relative;
  z-index: 1;
}
.kefubox {
  align-items: center;
  padding: 30px;
  border-radius: 12px;
  margin: 30px;
  background: #fff;
  box-shadow: 0 0 9px #ccc;
}
.headbox {
  width: 120px;
  height: 120px;
  margin-right: 30px;
}
</style>
